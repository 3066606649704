<template>
  <CCard
    v-if="item"
    class="candidate-card border-primary"
    :style="isJobSupplier ? 'min-height: 320px' : 'min-height: 350px'"
  >
    <div>
      <CandidateInfo :item="candidateInfoData" :index="index" />
    </div>
    <CCardBody v-if="!isStudent" class="content-block">
      <div class="d-flex flex-column justify-content-between">
        <CRow>
          <CCol class="lable" col="5">{{
            isNurse
              ? `Main Speciality`
              : isNonClinicAdmin
              ? `Main Expertise`
              : `Speciality`
          }}</CCol>
          <CCol
            v-if="isWrapped(speciality)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: speciality,
            }"
          >
            {{ speciality || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ speciality || "--" }}
          </CCol>
        </CRow>

        <CRow v-if="!isNurse && !isDentist && !isNonClinicAdmin">
          <CCol class="lable" col="5">
            {{ isAHP ? `Sub Type/License` : `Sub Speciality` }}</CCol
          >
          <CCol
            v-if="isWrapped(subSpeciality)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: subSpeciality,
            }"
          >
            {{ subSpeciality || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ subSpeciality || "--" }}
          </CCol>
        </CRow>

        <CRow v-if="isNurse || isNonClinicAdmin">
          <CCol class="lable" col="5"> Other Expertise </CCol>
          <CCol
            v-if="isWrapped(otherSpecialities)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: otherSpecialities,
            }"
          >
            {{ otherSpecialities || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ otherSpecialities || "--" }}
          </CCol>
        </CRow>

        <CRow>
          <CCol class="lable" col="5"> Qualification </CCol>
          <CCol
            v-if="isWrapped(qualificationName)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: qualificationName,
            }"
          >
            {{ qualificationName || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ qualificationName || "--" }}
          </CCol>
        </CRow>

        <CRow>
          <CCol class="lable" col="5"> Country Obtained </CCol>
          <CCol class="value" col="7">
            {{ qualificationCountry || "--" }}
          </CCol>
        </CRow>

        <CRow>
          <CCol class="lable" col="5"> Date Obtained</CCol>
          <CCol class="value" col="7">
            {{ qualificationDate || "--" }}
          </CCol>
        </CRow>

        <CRow v-if="isNurse">
          <CCol class="lable" col="5">Yrs since first Reg.</CCol>
          <CCol class="value" col="7">
            {{ yrsregistration || "--" }}
          </CCol>
        </CRow>

        <CRow v-if="isNurse || isNonClinicAdmin">
          <CCol class="lable" col="5">Working As</CCol>
          <CCol
            v-if="isWrapped(nurseLicenseLevel)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: nurseLicenseLevel,
            }"
          >
            {{ nurseLicenseLevel || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ nurseLicenseLevel || "--" }}
          </CCol>
        </CRow>

        <CRow v-if="isPQR">
          <CCol class="lable" col="5">{{
            isPQR ? `Suitable For` : `Working as`
          }}</CCol>
          <CCol
            v-if="isWrapped(pqrLevels)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: pqrLevels,
            }"
          >
            {{ pqrLevels || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ pqrLevels || "--" }}
          </CCol>
        </CRow>
      </div>
    </CCardBody>
    <CCardBody v-if="isStudent && !studentCourse" class="content-block">
      <div class="d-flex flex-column justify-content-between">
        <CRow>
          <CCol class="lable" col="5"> Course Title</CCol>
          <CCol class="value" col="7">
            {{ studentQualification.speciality_name || "--" }}
          </CCol>
        </CRow>
        <CRow>
          <CCol class="lable" col="5"> Level</CCol>
          <CCol class="value" col="7">
            {{ studentQualification.qualification_name || "--" }}
          </CCol>
        </CRow>
        <CRow>
          <CCol class="lable" col="5"> Mode</CCol>
          <CCol class="value" col="7">
            {{ studentQualification.full_part_time || "--" }}
          </CCol>
        </CRow>
        <CRow>
          <CCol class="lable" col="5"> Start Date</CCol>
          <CCol class="value" col="7">
            {{ dateFormat(studentQualification.course_start_date) || "--" }}
          </CCol>
        </CRow>
        <CRow>
          <CCol class="lable" col="5"> Completion Date</CCol>
          <CCol class="value" col="7">
            {{ dateFormat(studentQualification.predicted_completion_date) || "--" }}
          </CCol>
        </CRow>
      </div>
    </CCardBody>
    <CCardBody v-if="isStudent && studentCourse" class="content-block">
      <div class="d-flex flex-column justify-content-between">
        <CRow>
          <CCol class="lable" col="5"> Course Title</CCol>
          <CCol class="value" col="7">
            {{ trainingCourse.course_title || "--" }}
          </CCol>
        </CRow>
        <CRow>
          <CCol class="lable" col="5"> Mode</CCol>
          <CCol class="value" col="7">
            {{ studentCourse.full_part_time || "--" }}
          </CCol>
        </CRow>
        <CRow>
          <CCol class="lable" col="5"> Start Date</CCol>
          <CCol class="value" col="7">
            {{ dateFormat(studentCourse.course_start_date) || "--" }}
          </CCol>
        </CRow>
        <CRow>
          <CCol class="lable" col="5"> Completion Date</CCol>
          <CCol class="value" col="7">
            {{ dateFormat(studentCourse.course_end_date) || "--" }}
          </CCol>
        </CRow>
        <CRow>
          <CCol class="lable" col="5"> Distance/Online Learning</CCol>
          <CCol class="value" col="7">
            {{ studentCourse.distance_learning ? `Yes` : "No" }}
          </CCol>
        </CRow>
      </div>
    </CCardBody>
    <div class="d-flex justify-content-center pb-2">
      <!-- <CButton
        color="primary"
        variant="outline"
        class="mx-2 action-btn"
        @click="previewCV(cvDocument)"
        :disabled="!cvDocument || isJobSupplier"
      >
        CV/Resume</CButtonpreviewCV
      > -->
      <CDropdown
        color="primary"
        toggler-text="CV/Resume"
        class="mx-2 w-100"
        addTogglerClasses="w-100 btn action-btn btn-outline-primary"
        addMenuClasses="fs-12"
        :disabled="!cvDocument || isJobSupplier"
        :caret="false"
      >
        <CDropdownItem download :target="'_blank'" :href="cvDocument.link"
          >Download CV/Resume</CDropdownItem
        >
        <CDropdownItem href="javascript:void(0)" @click="previewCV(cvDocument)"
          >View CV/Resume</CDropdownItem
        >
      </CDropdown>
      <CButton
        color="primary"
        variant="outline"
        class="mx-2 action-btn"
        @click="navigateToCandidate(item)"
      >
        More Details</CButton
      >
    </div>
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <Modal
      :modalTitle="noCVModal.modalTitle"
      :modalColor="noCVModal.modalColor"
      :modalContent="noCVModal.modalContent"
      :isShowPopup="noCVModal.isShowPopup"
      :buttons="noCVModal.buttons"
      :modalCallBack="noCVModalCallback"
      :closeOnBackdrop="false"
      :stepperModal="noCVModal.stepperModal"
    />
  </CCard>
</template>

<script>
import CandidateInfo from "@/containers/CandidateList/CandidateInfo";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { getScope, Role, appendAccessToken, generateFullUrlWithToken  } from "@/helpers/helper";
import candidateFilter from "@/schema/candidateFilter";
import ResetVue from "../../views/pages/Reset.vue";
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import { BASE_URL } from "@/service_urls";
import Modal from "@/components/reusable/Modal";
import isJobSupplier from "@/mixins/isJobSupplier";
export default {
  name: "CandidateList",
  mixins: [isJobSupplier],
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      noCVModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        modalContent: "There is No CV / Resume Available for this Candidate",
        isShowPopup: false,
        buttons: ["OK"],
        stepperModal: true,
      },
      wrapMaxLength: 27,
      generateFullUrlWithToken,
    };
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
    index: {
      type: Number
    }
  },
  components: {
    CandidateInfo,
    PreviewModal,
    Modal,
  },
  computed: {
    ...mapGetters([
      "getUserId",
      "getCustomerId",
      "getCustomerTypeIDFromAccessToken",
    ]),
    isStudent(){
      return this.item.is_student || false;
    },
    yrsregistration() {
      const yrs = this.item.years_since_first_registration;
      return yrs ? `${yrs} Year${yrs > 1 ? "s" : ""}` : "--";
    },
    pqrLevels() {
      return this.item.eligibility && this.item?.pqr_country_code
        ? `${this.item.eligibility}(${this.item?.pqr_country_code})`
        : this.item.eligibility || "--";
    },
    nurseLicenseLevel() {
      return this.item?.level_name || "--";
    },
    isPQR() {
      if ([1,2].includes(this.item.candidate_type_id)) {
        return this.item.eligibility ? this.item.eligibility : false;
      } else if (this.item.candidate_type_id == 6) {
        return false;
      }
      return true;
    },
    candidateInfoData() {
      let {
        customer_uid,
        first_name,
        surname,
        email,
        gender,
        speciality,
        sub_speciality,
        candidate_level,
        candidate_type,
        nationality,
        age,
        phone,
        eligibility_letter_available_for,
        local_license,
        candidate_uid,
        whatsapp,
        retired_clinician,
        volunteer,
        profile_picture,
        highest_qualification_name,
        highest_qualification_acronym,
        last_date_of_login,
        created_on,
        agency_contact_id,
        organisation_name,
        display_uid,
        original_ohr_id,
        license_level_name,
        organisation_id,
        agency_main_contact_email,
        agency_main_contact_phone_no,
        shared_organisations,
        is_student,
        customer_type_id,
        is_private,
        is_sponsored,
        current_score,
      } = Object.assign(this.item, {
        customer_uid: this.item?.customer_uid,
        candidate_type: this.item.candidate_type_name || "",
        nationality: this.item.nationality_name || "",
        main_qualification: this.item.main_qualification || "",
        gender: this.item.gender_name || "",
        candidate_uid: this.item.candidate_uid || null,
        display_uid: this.item.display_uid || null,
        original_ohr_id: this.item.original_ohr_id || null,
        whatsapp: this.item.whatsapp || "",
        local_license: this.item.local_license,
        retired_clinician: this.checkData(this.item.retired_clinician),
        volunteer: this.checkData(this.item.volunteer),
        highest_qualification_name:
          this.item.highest_qualification_name || null,
        highest_qualification_acronym:
          this.item.highest_qualification_acronym || null,
        last_date_of_login: this.item?.last_date_of_login,
        created_on: this.item?.created_on,
        agency_contact_id: this.item?.agency_contact_id,
        organisation_name: this.item?.organisation_name,
        license_level_name: this.item?.license_level_name,
        organisation_id: this.item?.organisation_id,
        shared_organisations: this.item?.shared_organisations,
        is_student: this.item?.is_student,
        customer_type_id: this.item?.customer_type_id,
        is_private: this.item?.is_private,
        is_sponsored: this.item?.is_sponsored,
        current_score: this.item?.current_score,
      });

      eligibility_letter_available_for = eligibility_letter_available_for
        ? eligibility_letter_available_for.split(",").join(", ")
        : "--";
      return {
        customer_uid,
        first_name,
        surname,
        gender,
        speciality,
        sub_speciality,
        candidate_level,
        candidate_type,
        nationality,
        email,
        age,
        phone,
        whatsapp,
        eligibility_letter_available_for,
        local_license,
        candidate_uid,
        retired_clinician,
        volunteer,
        profile_picture,
        highest_qualification_name,
        highest_qualification_acronym,
        last_date_of_login,
        created_on,
        agency_contact_id,
        organisation_name,
        display_uid,
        original_ohr_id,
        organisation_id,
        agency_main_contact_email,
        agency_main_contact_phone_no,
        shared_organisations,
        is_student,
        customer_type_id,
        is_private,
        is_sponsored,
        current_score,
      };
    },
    specialisation() {
      let specialisation_data = null;
      this.item.qualifications.some((e) => {
        if (e.on_specialist_register) {
          specialisation_data = e;
          return true;
        }
        return false;
      });
      return specialisation_data;
    },
    splqualification_classification_name() {
      return (
        this.specialisation?.qualification?.qualification_classification_name ||
        null
      );
    },
    splqualification_country_name() {
      return this.specialisation?.country_name || null;
    },
    highestQualification() {
      let highestQualification = null;
      this.item.qualifications &&
        this.item.qualifications.length &&
        this.item.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
            return true;
          }
          return false;
        });
      return highestQualification;
    },
    qualification() {
      return this.highestQualification?.qualification?.acronym || null;
    },
    qualificationCountry() {
      return this.item?.country_obtained || null;
    },
    qualificationDate() {
      return this.item?.date_obtained;
    },
    cvDocument() {
      if (this.item.cv_document && this.item.cv_ext) {
        const { link_to_cv_doc } = this.item;
        // const _base_domain = "https://" + window.location.host;
        return {
          link: generateFullUrlWithToken(link_to_cv_doc),
          document_ext: this.item.cv_ext,
          // link_to_cv_doc: appendAccessToken(
          //   `${_base_domain}${link_to_cv_doc}`
          // ),
        };
      }
      return false;
    },
    isNurse() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 2
        : false;
    },
    isAHP() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 3
        : false;
    },
    isDentist() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 4
        : false;
    },
    isNonClinicAdmin() {
      return this.item?.candidate_type_id
        ? this.item.candidate_type_id === 6
        : false;
    },
    nurseOtherExpertise() {
      return (
        _(this.item?.candidate_specialities)
          .filter((speciality) => {
            return (
              speciality.main != true &&
              !speciality.other_special_interest &&
              !speciality.special_interest
            );
          })
          .map((speciality) => {
            return speciality.speciality
              ? speciality.speciality?.speciality
              : speciality?.other_speciality;
          })
          .value()
          .join(", ") || "--"
      );
    },
    qualificationName() {
      return this.item?.highest_qualification_acronym
        ? this.item?.highest_qualification_acronym
        : this.item?.highest_qualification_name;
    },
    speciality() {
      if (this.isNurse || this.isNonClinicAdmin)
        return this.item?.main_speciality;
      return this.item?.speciality_name;
    },
    subSpeciality() {
      if (this.isNurse) return this.item?.other_expertise;
      if (this.isAHP) return this.item?.sub_type_license;
      return this.item?.sub_speciality_name;
    },
    otherSpecialities() {
      return this.item?.other_expertise;
    },
    isEducationalInstitute() {
      return this.getCustomerTypeIDFromAccessToken === 5 || false;
    },
    studentQualification() {
      return this.item?.student_qualification?.[0] || {}
    },
    studentCourse() {
      return this.item?.student_course
    },
    trainingCourse() {
      return this.item?.student_course.training_course
    },
  },
  methods: {
    ...mapActions([
      "setCandidate",
      "setSelectedcandidateById",
      "setCandidateListBackUrl",
      "createshortlist",
      "releaseCandidate",
      "resetCandidateDetail",
      "createInterviewlist",
      "createRecruitList",
      "fetchFacilityConfig",
      "fetchJobs",
      "showToast",
      "getCandidateDetails",
    ]),

    navigateToCandidate(item) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.resetCandidateDetail();
      this.$store.commit("SET_CANDIDATE_INFORMATION_STEP", 1);

      const { params } = this.$route;
      if (
        params?.customer_type === "super_customer" &&
        item.is_super_customer_candidate
      ) {
        this.$router.push({
          path: `/candidate/${item.candidate_uid}/super_customer`,
        });
      } else this.$router.push({ path: `/candidate/${item.candidate_uid}` });
    },

    checkData(value) {
      if (value !== null) {
        return value === "Yes" || value === true ? "Yes" : "No";
      }
      return "--";
    },
    navigateToJobList({ job_id }) {
      this.$router.push({ path: `/jobs-list/?job_id=${job_id}` });
    },
    getDtSpl(qualification) {
      if (qualification != null) {
        let month_num = qualification.specialist_registration_month || null;
        let month_str = null;
        if (month_num) month_str = moment.monthsShort()[month_num - 1];
        return `${month_str} ${qualification.specialist_registration_year}`;
      }
      return null;
    },
    previewCV(data) {
      if (data) {
        this.preview(data);
      } else {
        this.noCVModal.isShowPopup = true;
      }
    },
    preview(data) {
      this.iframe.url = data.link;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data?.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = data.link;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data?.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data?.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" + data.link;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    noCVModalCallback(action) {
      this.noCVModal.isShowPopup = false;
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    isWrapped(data) {
      return data?.length > this.wrapMaxLength || false;
    },
    isWrappedQual(data) {
      return data?.highest_qualification_acronym == null
        ? data?.highest_qualification_name?.length > this.wrapMaxLength
        : false;
    },
    dateFormat(date) {
      return date ? moment(date, "YYYY-MM-DD").format("DD-MMM-YYYY") : "--";
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("MMM YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.btnmargin {
  margin-right: 15px;
  padding-top: 0.1rem;
  padding-right: 0.5rem;
  padding-bottom: 0.05rem;
  padding-left: 0.5rem;
  font-size: 12px;
}
.btncol {
  text-align: center;
  vertical-align: middle;
}
.lblspan {
  font-weight: bold;
}
.flex-1 {
  font-size: 13.5px;
}
.flex-2 {
  font-size: 13.5px;
}
.add-details-btn {
  max-width: 200px;
}
.icon-wrapper {
  .icon {
    width: 50px;
    img {
      width: 35px;
      padding: 5px;
      text-align: center;
      cursor: pointer;
    }
    img.disabled {
      // pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5 !important;
    }
  }
}
.left-side-width {
  width: 40%;
}
.right-side-width {
  width: 70%;
}
.action-btn {
  width: 100%;
  font-size: 11px;
}
.btn:disabled {
  cursor: not-allowed;
}
</style>
